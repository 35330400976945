<template>
    <div class="container">
  
        <!-- Add Employee -->
        <form id="formAddEmployee">
            <modal-add-edit-employee modalId="modalAddEmployee" title="Dodavanje radnika" mode="ADD" :employee.sync="newEmployee" @confirmed="addEmployee"></modal-add-edit-employee>
        </form>

        <!-- Edit Employee -->
        <form id="formEditEmployee">
            <modal-add-edit-employee modalId="modalEditEmployee" title="Izmena radnika" mode="EDIT" :employee.sync="employeeForUpdate" @confirmed="updateEmployee"></modal-add-edit-employee>
        </form>

        <!-- History Modal -->
        <div class="modal fade" id="modalDateHistory" tabindex="-1" aria-labelledby="modalDateHistoryLabel" aria-hidden="true">
            <ModalDateHistory v-bind:employee=employeeForHistory v-bind:dataName='dataName' v-bind:historyData='historyData'/>
        </div>

        <modal-confirm @confirm="confirmAction" inputId="confirmDeleteModal" title="Potvrda brisanja radnika" :message="confirmMessage"/>


        <div class="table-wrapper">
            <div class="table-title">
                <div class="row">
                    <div class="col">
                        <h2>Radnici</h2>
                    </div>
                    <div v-if="user.role === 'ADMIN'" class="col-sm-7">
                        <div class="ui-button">
                            <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAddEmployee"><MenuIcon class="icon-2x" /><span class="btn-title">Dodaj novog radnika</span></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 control-section" v-if="employees">
                <div>
                    <ejs-grid
                        ref="empGrid"
                        :dataSource="employees" 
                        class="sortingenabled employees-grid-info"
                        :allowFiltering='true'
                        :allowSorting='true'
                        :showColumnMenu='true'
                        :groupSettings='groupOptions'
                        :filterSettings='filterSettings'
                        :recordDoubleClick="employeesDBClick"
                        :rowSelected="employeeSelect"
                        :rowDeselected="employeeDeselect"
                        :toolbar='toolbar'
                        :toolbarClick='clickHandler'
                        :rowDataBound='rowDataBound'
                        :allowResizing='true'
                        :allowReordering='true'
                        :enablePersistence='true'
                        :allowExcelExport='true'
                        :allowPdfExport='true'
                        >
                        <e-columns>
                            <e-column field='' headerText='#' :template="rowIndexTemplate" width='60' textAlign='Right' :visible='true' ></e-column>

                            <e-column field='id' headerText='ID' width='120' textAlign='Left' :isPrimaryKey='true' :visible='false' ></e-column>
                            <e-column field='user.username' headerText='Korisničko ime' width='120' textAlign='Left' :template="usernameTemplate"></e-column>
                            <e-column field='user.last_name' headerText='Prezime' width='150'></e-column>
                            <e-column field='user.first_name' headerText='Ime' width='150'></e-column>
                            
                            <e-column :valueAccessor='baseAccessor' field='jmbg' headerText='Jmbg' width='150' :visible='false'></e-column>
                            <e-column :valueAccessor='dateAccessor' field='birthdate' headerText='Datum rođenja' width='130' format="yMd" textAlign='Right' :visible='false'></e-column>
                            <e-column :valueAccessor='baseAccessor' field='birthplace' headerText='Mesto rođenja' width='130' :visible='false'></e-column>
                            <e-column :valueAccessor='baseAccessor' field='education' headerText='Zanimanje' width='150'></e-column>
                            <e-column :valueAccessor='baseAccessor' field='phone' headerText='Telefon' width='150'></e-column>
                            <e-column :valueAccessor='baseAccessor' field='user.email' headerText='Email' width='150' :visible='false'></e-column>
                            <e-column :valueAccessor='dateAccessor' field='work_start_date' headerText='Početak rada' width='150' :visible='false'></e-column>
                            <e-column :valueAccessor='dateAccessor' field='application_start_date' headerText='Datum prijave' width='150'></e-column>
                            <e-column :valueAccessor='dateAccessor' field='application_end_date' headerText='Datum isteka prijave' width='150'></e-column>
                            <e-column :valueAccessor='dateAccessor' field='work_end_date' headerText='kraj rada' width='150' :visible='false'></e-column>
                            <e-column :valueAccessor='dateAccessor' field='medical_examination_date' headerText='Datum lekarskog pregleda' width='150' :visible='false'></e-column>
                            <e-column :valueAccessor='dateAccessor' field='health_card_expiration_date' headerText='Datum isteka zdravstvene knjižice' width='150' :visible='false'></e-column>
                            <e-column :valueAccessor='dateAccessor' field='religious_holiday_date' headerText='Verski praznik' width='150' :visible='false'></e-column>
                            <e-column :valueAccessor='baseAccessor' field='shoe_size' headerText='Broj cipela' width='150' :visible='false'></e-column>
                            <e-column :valueAccessor='transportTypeAccessor' field='transport_type' headerText='Tip prevoza' width='150' :visible='false'></e-column>
                            <e-column :valueAccessor='roleAccessor' field='user.role' headerText='Uloga' width='150'></e-column>
                            <e-column headerText='Radni status' width='150' :template="employeeStatusTemplate"></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import MenuIcon from 'vue-material-design-icons/Menu.vue';
    import ModalAddEditEmployee from '@/components/modals/ModalAddEditEmployee';
    import ModalDateHistory from '@/components/modals/ModalDateHistory.vue';
    import { Modal } from 'bootstrap';
    import {mapState} from 'vuex';
    import UsernameTemplate from '@/components/grid-templates/UsernameTemplate.vue';
    // import Vue from 'vue';

    import EmployeesService from '@/service/EmployeesService.js';
    import UtilService from '@/service/UtilService.js';
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Reorder, Resize, PdfExport, ExcelExport } from "@syncfusion/ej2-vue-grids";
    import ModalConfirm from '@/components/modals/ModalConfirm.vue';
    import RowIndexTemplate from '@/components/grid-templates/RowIndex.vue';
    import EmployeeStatusTemplate from '@/components/grid-templates/EmployeeStatus.vue';



    export default {

        components: { MenuIcon, ModalAddEditEmployee, ModalDateHistory, ModalConfirm },

        setup: {
            UtilService
        },
        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Reorder, Resize, PdfExport, ExcelExport  ]
        },
        data: function() {
            return {
                employees: [],
                filters: [],

                modalAddEmployee: null,
                modalEditEmployee: null,
                modalDateHistory: null,
                confirmDeleteModal: null,

                newEmployee: {
                    user: {}
                },
                employeeForUpdate: {
                    user: {}
                },
                
                
                workOrder: null,
                selectedEmployee: {},
                currentWorkOrder: {
                    workOrderId: null,
                    date: null
                },

                employeeForHistory: {},
                dataName: "",
                historyData: {},

                toolbar: [
                    { text: 'Dodaj', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addEmployee' }, 
                    { text: 'Izmeni', tooltipText: 'Izmeni', prefixIcon: 'e-edit', id: 'editEmployee', disabled: false}, 
                    { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deleteEmployee', disabled: false},
                    { text: 'Povrati', tooltipText: 'Povrati', prefixIcon: 'e-delete', id: 'restoreEmployee', visible: false},
                    
                    { text: 'Exprot Excel', tooltipText: 'Izvezi Excel', prefixIcon: 'e-excelexport', id: 'exportExcel', align: 'Right'},
                    { text: 'Exprot PDF', tooltipText: 'Izvezi PDF', prefixIcon: 'e-pdfexport', id: 'exportPDF', align: 'Right'},
                    { text: 'Print', tooltipText: 'Štampaj', prefixIcon: 'e-print', id: 'print', align: 'Right'},
                    { text: 'Prikaži neaktivne', tooltipText: 'Prikaži neaktivne', prefixIcon: 'e-insert-column-right', id: 'showDeleted', align: 'Right'},
                    { text: 'Sakrij neaktivne', tooltipText: 'Sakrij neaktivne', prefixIcon: 'e-delete-column-right', id: 'hideDeleted', align: 'Right', visible: false}
                    ],
 
                groupOptions: { showGroupedColumn: true },
                filterSettings: { type: "Excel" },

                confirmMessage: "Da li ste sigurni da želite da obrišete radnika ?",
                confirmAction: function () {
                    alert("test");
                },

                showDeleted: false,

                usernameTemplate: function() {
                    return {
                        template: UsernameTemplate
                    };
                },

                employeeStatusTemplate() {
                    return {
                        template: {
                            extends: EmployeeStatusTemplate
                        }
                    }
                },

                rowIndexTemplate() {
                    return {
                        template: {
                            extends: RowIndexTemplate,
                        }
                    }
                }


            };
        },

        computed: {
            ...mapState([
                'user',
            ]),

            filteredEmployees() {
                if (this.filters.length === 0)
                    return this.employees;

                let result = this.employees;
                for (let filter of this.filters) {
                    let value = filter['value'];
                    let attr = filter['attr'];
                    result = attr === 'role' ? result.filter(e => e.user[attr] === value) : result.filter(e => e[attr] === value);
                }
                return result;
            }
        },

        created() {
            this.loadAllEmployees();
        },
        
        mounted() {
            this.modalAddEmployee = new Modal(document.getElementById('modalAddEmployee'));
            this.confirmDeleteModal = new Modal(document.getElementById('confirmDeleteModal'));
        },

        methods: {
            formatDate: UtilService.formatDate,
            translateTransportType: UtilService.translateTransportType,
            translateRole: UtilService.translateRole,
            checkEmployeeWarning: UtilService.checkEmployeeWarning,

            baseAccessor(field, data) {
                return data[field] ? data[field] : '/';
            },

            roleAccessor(field, data) {
                return this.translateRole(data['user'].role);
            },

            transportTypeAccessor(field, data) {
                return this.translateTransportType(data['transport_type']);
            },

            statusAccessor(field, data) {
                return data['employee_status'] === 'ACTIVE' ? 'Aktivan' : 'Neaktivan'; 
            },

            dateAccessor(field, data) {
                return data[field] ? this.formatDate(data[field]) : '/';
            },

            rowDataBound(args) {
                if (args.data?.user?.deleted == true)
                    args.row.classList.add('deleted-row');
                if (!this.employeeActive(args.data))
                    args.row.classList.add('inactive-row');
                else if (Object.keys(args.data?.warnings).length > 0 )
                    args.row.classList.add('warning-row');
            },

            async loadAllEmployees() {
                if (this.showDeleted) {
                    await EmployeesService.getDeletedAllEmployees()
                    .then(response => { this.employees = response.data; })
                    .catch(error => this.toast(error.message, 'error'));
                } else {
                    await EmployeesService.getAllEmployees()
                    .then(response => { this.employees = response.data; })
                    .catch(error => this.toast(error.message, 'error'));
                }
                this.calculateWarnings();
            },

            calculateWarnings() {
                this.employees.forEach(employee => {
                    employee.warnings = this.checkEmployeeWarning(employee);
                });
            },

            deleteSelectedEmployee() {
                let selected = (this.$refs.empGrid.ej2Instances.getSelectedRecords());
                if (selected.length > 0) selected = selected[0];
                else return;

                this.deleteEmployee(selected)
            },

            restoreSelectedEmployee() {
                let selected = (this.$refs.empGrid.ej2Instances.getSelectedRecords());
                if (selected.length > 0) selected = selected[0];
                else return;

                this.restoreEmployee(selected)
            },

            async deleteEmployee(data) {
                try {
                    await EmployeesService.deleteEmployee(data)
                    this.toast('Uspešno brisanje radnika.');
                    this.loadAllEmployees();
                } catch (error) {
                        console.log(error)
                        alert("Neuspesno")
                }

            },

            async restoreEmployee(data) {
                try {
                    data.user.deleted = false;
                    await EmployeesService.updateEmployee(data)
                    this.toast('Uspešno vraćanje radnika.');
                    this.loadAllEmployees();
                } catch (error) {
                        console.log(error)
                        alert("Neuspesno")
                }

            },

            validateForm(form) {
                form.classList.add('was-validated');
                for (let el of this.employees) {
                    if (this.newEmployee.user.username === el.user.username) {
                        this.newEmployee.user.username = "";
                        return false;
                    }
                }
                return form.checkValidity();
            },

            validateUpdateForm(form) {
                form.classList.add('was-validated');
                return form.checkValidity();
            },

            addEmployee() {
                let form = document.getElementById("formAddEmployee");
                if (this.validateForm(form) === false)
                    return;      

                this.newEmployee.employee_status = 'ACTIVE';
                EmployeesService.addEmployee(this.newEmployee)
                .then((response) => {
                    this.modalAddEmployee.hide();   
                    this.loadAllEmployees(); 
                    this.toast('Radnik ' + response.data.user.first_name + ' ' + response.data.user.last_name + ' je uspešno kreiran.');
                    this.newEmployee = { user: {} };
                }).catch((error) => alert(error.message) );
            },

            openUpdateModal(employee) {
                this.employeeForUpdate = JSON.parse(JSON.stringify(employee));                
                this.modalEditEmployee = new Modal(document.getElementById('modalEditEmployee'));    
                this.modalEditEmployee.show();
            },

            openWorkOrdersModal(employee) {
                this.selectedEmployee = JSON.parse(JSON.stringify(employee));
                this.workOrdersModal = new Modal(document.getElementById('workOrdersModal'))
                this.workOrdersModal.show();
            },

            updateEmployee() {
                let form = document.getElementById("formEditEmployee");
                if (this.validateUpdateForm(form) === false)
                    return;

                EmployeesService.updateEmployee(this.employeeForUpdate)
                .then(response => {
                    this.modalEditEmployee.hide();    
                    this.loadAllEmployees() 
                    this.toast("Zaposleni " + response.data.user.first_name + " " + response.data.user.last_name + " je uspešno izmenjen!", "info");
                }).catch((error) => { this.toast(error.message, 'error'); });
            },

            updateWorkOrders() {
                EmployeesService.addCurrentWorkOrder(this.selectedEmployee.id, this.currentWorkOrder.workOrderId, this.formatDate(this.currentWorkOrder.date))
                .then(response => { 
                    this.loadAllEmployees(); 
                    this.toast("Zaposlenom " + response.data.user.first_name + " " + response.data.user.last_name + " je uspešno dodat radni nalog"); })
                .catch((error) => { this.toast(error.message, 'error'); });
            },

            changeWorkOrder(userId, e) {
                EmployeesService.changeWorkOrder(userId, e.target.value)
                .then(response => { console.log(response); this.loadAllEmployees(); })
                .catch((error) => { this.toast(error.message, 'error'); });
            },

            sortEmployees(event) {
                let temp = event.target.value.split("-");
                let value = temp[0];
                let order = temp[1];

                if (value === 'name')
                    this.employees.sort((e1, e2) => ((e1.user.last_name + e1.user.first_name).localeCompare(e2.user.last_name + e2.user.first_name)));
                else
                    this.employees.sort((e1, e2) => { return e1[value] > e2[value] ? -1 : 1});

                if (order === 'descending')
                    this.employees.reverse();
            },
 
            filterEmployees(event, attr) {
                let value = event.target.value
                for (let filter of this.filters) {
                    if (filter.attr === attr) {
                        filter.value = value;
                        return;     
                    }
                }

                this.filters.push({'attr': attr, 'value': value});
            },

            // === History ===

            setDataAndShowHistoryView(employee, historyData) {
                this.employeeForHistory = employee.user.first_name + ' ' + employee.user.last_name
                this.historyData = historyData;
                for (let el of this.historyData) {
                    el.data = this.formatDate(el.data);
                    el.history_date = UtilService.formatDateTime(el.history_date);
                }
                this.modalDateHistory = new Modal(document.getElementById('modalDateHistory'));    
                this.modalDateHistory.show();
            },
            showHistoryForApplicationStartDate(employee) {
                EmployeesService.getApplicationStartDateHistory(employee.id)
                .then(response => { 
                    this.dataName = "Datum prijave";
                    this.setDataAndShowHistoryView(employee, response.data);
                })
                .catch((error) => { this.toast(error.message, 'error'); });
            },
            showHistoryForApplicationEndDate(employee) {
                EmployeesService.getApplicationEndDateHistory(employee.id)
                .then(response => { 
                    this.dataName = "Datum važenja prijave";
                    this.setDataAndShowHistoryView(employee, response.data);
                })
                .catch((error) => { this.toast(error.message, 'error'); });
            },
            showHistoryForCheckoutDate(employee) {
                EmployeesService.getCheckoutDateHistory(employee.id)
                .then(response => { 
                    this.dataName = "Datum odjave";
                    this.setDataAndShowHistoryView(employee, response.data);
                })
                .catch((error) => { this.toast(error.message, 'error'); });
            },
            showHistoryForMedicalExaminationDate(employee) {
                EmployeesService.getMedicalExaminationDateHistory(employee.id)
                .then(response => { 
                    this.dataName = "Lekarski pregled";
                    this.setDataAndShowHistoryView(employee, response.data);
                })
                .catch((error) => { this.toast(error.message, 'error'); });
            },
            showHistoryForHealthCardExpirationDate(employee) {
                EmployeesService.getHealthCardExpirationDateHistory(employee.id)
                .then(response => { 
                    this.dataName = "Zdravstvena";
                    this.setDataAndShowHistoryView(employee, response.data);
                })
                .catch((error) => { this.toast(error.message, 'error'); });
            },
            showHistoryForReligiousHoliday(employee) {
                EmployeesService.getReligiousHolidayHistory(employee.id)
                .then(response => { 
                    this.dataName = "Verski praznik";
                    this.setDataAndShowHistoryView(employee, response.data);
                })
                .catch((error) => { this.toast(error.message, 'error'); });
            },
            toast(message, type) {
                this.$toasted.show(message, { 
                    type: type,
                    theme: "toasted-primary", 
                    position: "top-center", 
                    duration : 3000
                });
            },
            employeesDBClick(event) {
                this.openUpdateModal(event.rowData)
            },

            clickHandler(args) {
                if (args.item.id === 'addEmployee') {
                    this.showAddEmployeeModal()
                }

                else if (args.item.id === "editEmployee"){
                    let selected = (this.$refs.empGrid.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.openUpdateModal(selected)
                }

                else if (args.item.id === "deleteEmployee"){
                    let selected = (this.$refs.empGrid.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;
                    
                    this.confirmMessage = "Da li ste sigurni da želite da obrišete: " + selected.user.first_name + " " + selected.user.last_name + "?";

                    this.confirmAction = this.deleteSelectedEmployee;
                    this.confirmDeleteModal.show();
                }

                else if (args.item.id === "restoreEmployee"){
                    let selected = (this.$refs.empGrid.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    
                    else return;
                    
                    this.confirmMessage = "Da li ste sigurni da želite da vratite nalog: " + selected.user.first_name + " " + selected.user.last_name + "?";
                    this.confirmAction = this.restoreSelectedEmployee;
                    this.confirmDeleteModal.show();
                }

                else if (args.item.id === "showDeleted"){

                    this.toggleShowDeleted();
                }
                else if (args.item.id === "hideDeleted"){

                    this.toggleHideDeleted();
                }
                else if (args.item.id === "exportExcel") {
                    this.$refs.empGrid.excelExport();
                }
                else if (args.item.id === "exportPDF") {
                    this.$refs.empGrid.pdfExport();
                }
                else if (args.item.id === "print") {
                    this.$refs.empGrid.print();
                }


            },

            test() {
                alert("radii");
            },

            toggleShowDeleted() {
                
                this.showDeleted = true;
                console.log(this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items);
                this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[7].visible = false;
                this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[8].visible = true;
                this.loadAllEmployees();

            },

            toggleHideDeleted() {

                this.showDeleted = false;
                this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[7].visible = true;
                this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[8].visible = false;
                this.loadAllEmployees();

            },

            showAddEmployeeModal() {
                this.modalAddEmployee.show();   
            },

            async employeeSelect(event) {

                this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[1].visible = true;
                

                this.refreshToolbar(event.data);
            },


            async employeeDeselect() {
                    this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[1].disabled = true;
                    this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[2].disabled = true;
                    this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[3].disabled = true;
            },

            refreshToolbar(data) {


                if (data.user.deleted) {
                    

                    this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[1].disabled = true;

                    this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[2].visible = false;
                    this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[3].visible = true;

                    this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[2].disabled = true;
                    this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[3].disabled = false;
                } else {
                    this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[1].disabled = false;

                    this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[2].visible = true;
                    this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[3].visible = false;

                    this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[2].disabled = false;
                    this.$refs.empGrid.ej2Instances.toolbarModule.toolbar.items[3].disabled = true;
                }


            },

            
            employeeActive (emp) {
                if (!emp.work_end_date) return true;

                let checkout = UtilService.convertMoment(emp.work_end_date)
                if (checkout.diff(UtilService.getMoment(), 'days') < 0){
                    return false
                }

                return true;
            },
        },
    };
    
</script>

<style>
    .employees-grid-info tr.deleted-row {
        background: rgb(255, 138, 138);
    }
    .employees-grid-info tr.deleted-row:hover {
        background: rgb(255, 115, 115) !important;
    }

    

    .employees-grid-info tr.inactive-row {
        background: rgb(171, 171, 171);
    }


    .e-delete-column-right:before{
        content: '\ea96';
    }
    .e-insert-column-right:before{
        content: '\ea97';
    }
        
    .employees-grid-info.e-grid .e-toolbar  { 
        position: -webkit-sticky; 
        position: sticky; 
        top: 0px;
        z-index: 1; 
    } 

    .employees-grid-info.e-grid .e-gridheader { 
        position: -webkit-sticky; 
        position: sticky; 
        top: 38px; /* The height of top nav menu. */ 
        /* border-bottom: 1px solid #222; */
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.142);
        z-index: 1; 
    } 
</style>

<style scoped>

.container {
    min-height: 74vh;
}

.table-title {
    padding-bottom: 15px;
    background: #299be4;
    color: #fff;
    padding: 16px 30px;
    margin: 0 -25px 10px;
    border-radius: 3px 3px 0 0;
}

.table-title h2{
    margin: 5px 0 0;
    font-size: 1.8em;
}

.table-title .btn {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
}

.table-title .btn:hover, .table-title .btn:focus {
    color: #566787;
    background: #f2f2f2;
}

.table-title .btn .btn-title {
    margin-top: 20px;
}

.table-wrapper {
    background: #fff;
    /* padding: 20px 25px; */
    border-radius: 3px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}


.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfcfc;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
}
table.table thead {
    font-size: 0.8em
}

.h4 {
    font-style: bold;
}

.card-img-top {
    width: 100%;
    height: 4vw;
    min-height: 150px;
    object-fit: cover;
}

#cards .card {
    min-height: 100px;
}

#cards .card-body {
    background: #ffffffa7;
    position: absolute;
    bottom: 0;
    width: 100%;
}

#cards .card:hover .card-body {
    background: #ffffffef;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.filter {
    margin-bottom: 1%;
}

.sort {
    width: auto;
    margin-bottom: 1%;
}

.filter-by {
    width: auto;
    flex: 0 0 auto;
}

.sort-filter {
    margin-bottom: 1%;
}

</style>
